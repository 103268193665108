import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

import Layout, { mq } from "../components/layout";
import Header from "../components/Header";
import * as colors from "../utils/colors";

const HowToPage = ({ location }) => {
  const { howToPosts } = useStaticQuery(
    graphql`
      {
        howToPosts: allMarkdownRemark(
          filter: { fields: { folder: { eq: "how-to" } } }
          sort: { fields: [frontmatter___date], order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              fields {
                slug
              }
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    `
  );

  return (
    <Layout preTitle="How to" pathname={location.pathname}>
      <Header />
      <div
        css={css`
          max-width: 90rem;
          margin: auto;
          padding: 3rem 1rem 0 1rem;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        `}
      >
        <h2
          css={css`
            text-align: center;
          `}
        >
          How to
        </h2>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            max-width: 50rem;
            width: 100%;
            margin: auto;
            flex-grow: 1;
          `}
        >
          {howToPosts.edges.map(({ node }) => (
            <div
              key={node.id}
              css={css`
                display: block;
                ${mq[1]} {
                  display: flex;
                }
                margin: 2rem 0;
              `}
            >
              <div
                css={css`
                  width: 100%;
                  max-width: 15rem;
                `}
              >
                <GatsbyImage
                  image={getImage(node.frontmatter.image)}
                  alt={node.frontmatter.title}
                />
              </div>
              <div
                css={css`
                  max-width: 40rem;
                `}
              >
                <Link
                  to={node.fields.slug}
                  css={css`
                    color: unset;
                    text-decoration: none;
                  `}
                >
                  <h3
                    css={css`
                      ${mq[1]} {
                        margin-top: 0;
                      }
                    `}
                  >
                    {node.frontmatter.title}
                  </h3>
                </Link>
                <p>{node.excerpt}</p>
                <Link
                  to={node.fields.slug}
                  css={css`
                    color: ${colors.GRAPIC_TURQUOISE};
                    font-weight: 600;
                    font-family: "Poppins", serif;
                    text-decoration: none;
                  `}
                >
                  Read more →
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div
          css={css`
            margin: 0 auto;
          `}
        >
          <StaticImage
            alt="Illustration"
            src="../assets/illustrations/Rocket_Pen.png"
            width={540}
            formats={["auto", "webp", "avif"]}
            placeholder={"tracedSVG"}
          />
        </div>
      </div>
    </Layout>
  );
};

export default HowToPage;
